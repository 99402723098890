var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"py-10 px-8"},[_c('strong',[_vm._v("Filtros")]),_c('v-row',{staticClass:"pt-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"small":"","label":"Creación"},model:{value:(_vm.logs.what.create),callback:function ($$v) {_vm.$set(_vm.logs.what, "create", $$v)},expression:"logs.what.create"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"small":"","label":"Edición"},model:{value:(_vm.logs.what.update),callback:function ($$v) {_vm.$set(_vm.logs.what, "update", $$v)},expression:"logs.what.update"}})],1)],1),_c('v-select',{attrs:{"items":_vm.types,"item-text":"label","item-value":"value","attach":"","chips":"","label":"Tipo","multiple":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()}},model:{value:(_vm.logs.type),callback:function ($$v) {_vm.$set(_vm.logs, "type", $$v)},expression:"logs.type"}}),_c('v-select',{attrs:{"items":_vm.userLists,"item-text":"name","item-value":"id","attach":"","chips":"","label":"Usuario","multiple":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()}},model:{value:(_vm.logs.user_id),callback:function ($$v) {_vm.$set(_vm.logs, "user_id", $$v)},expression:"logs.user_id"}}),_c('div',{staticClass:"mt-4"},[_vm._v(" Fecha "),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Desde","clearable":"","prepend-icon":"mdi-calendar-today","readonly":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()}},model:{value:(_vm.logs.dateFrom),callback:function ($$v) {_vm.$set(_vm.logs, "dateFrom", $$v)},expression:"logs.dateFrom"}},on))]}}]),model:{value:(_vm.menuFrom),callback:function ($$v) {_vm.menuFrom=$$v},expression:"menuFrom"}},[_c('v-date-picker',{attrs:{"color":"primary","clearable":""},on:{"input":function($event){_vm.menuFrom = false}},model:{value:(_vm.logs.dateFrom),callback:function ($$v) {_vm.$set(_vm.logs, "dateFrom", $$v)},expression:"logs.dateFrom"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Hasta","clearable":"","prepend-icon":"mdi-calendar","readonly":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()}},model:{value:(_vm.logs.dateTo),callback:function ($$v) {_vm.$set(_vm.logs, "dateTo", $$v)},expression:"logs.dateTo"}},on))]}}]),model:{value:(_vm.menuTo),callback:function ($$v) {_vm.menuTo=$$v},expression:"menuTo"}},[_c('v-date-picker',{attrs:{"color":"primary"},on:{"input":function($event){_vm.menuTo = false}},model:{value:(_vm.logs.dateTo),callback:function ($$v) {_vm.$set(_vm.logs, "dateTo", $$v)},expression:"logs.dateTo"}})],1)],1),(_vm.company==undefined)?_c('v-autocomplete',{attrs:{"items":_vm.companyLists,"item-text":"name","item-value":"id","attach":"","chips":"","label":"Empresa","multiple":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()}},model:{value:(_vm.logs.company_id),callback:function ($$v) {_vm.$set(_vm.logs, "company_id", $$v)},expression:"logs.company_id"}}):_vm._e(),_c('v-autocomplete',{attrs:{"item-text":"name","items":_vm.contactLists,"item-value":"id","attach":"","chips":"","label":"Contacto","multiple":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-2"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" "),(_vm.logs.company_id==null || _vm.logs.company_id.length>1 || _vm.logs.company_id.length==0)?_c('v-chip',{staticClass:"ml-2",staticStyle:{"font-size":"8px"},attrs:{"small":""}},[_vm._v(_vm._s(_vm.companyName(item.company_id)))]):_vm._e()],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.logs.contact_id),callback:function ($$v) {_vm.$set(_vm.logs, "contact_id", $$v)},expression:"logs.contact_id"}},[_c('template',{staticClass:"pa-2",slot:"no-data"},[_vm._v("No existen contactos relacionados.")])],2),_c('v-autocomplete',{attrs:{"items":_vm.userLists,"item-text":"name","item-value":"id","attach":"","chips":"","label":"Vendedor","multiple":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter()}},model:{value:(_vm.logs.salesmen),callback:function ($$v) {_vm.$set(_vm.logs, "salesmen", $$v)},expression:"logs.salesmen"}})],1),_c('v-card',{staticStyle:{"position":"absolute","bottom":"0","width":"100%"},attrs:{"tile":"","color":"primary"}},[_c('v-list-item',{attrs:{"link":"","dark":""},on:{"click":function($event){return _vm.filter()}}},[_c('v-list-item-content',[_c('strong',[_vm._v("Filtrar (0)")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }